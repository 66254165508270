import { definitions } from '@luxuryescapes/contract-svc-lux-loyalty'

type ServerLuxLoyaltyAccount = definitions['accountSummary']
type ServerLuxLoyaltyPendingPoints = definitions['pendingPoints']

export function luxLoyaltyAccountMapper(response: ServerLuxLoyaltyAccount | null): App.LuxLoyaltyAccount | undefined {
  if (!response) return undefined

  return {
    accountNumber: response.accountNumber,
    points: response.points,
    tier: response.tier,
    statusCredits: response.statusCredits,
    ibsMemberId: response.ibsMemberId,
    statusReviewDate: response.statusReviewDate ?? undefined,
    totalCreditsForTierRetention: response.totalCreditsForTierRetention ?? undefined,
    totalCreditsForTierUpgrade: response.totalCreditsForTierUpgrade ?? undefined,
    numberOfNights: response.numberOfNights ?? undefined,
    numberOfNightsForTierUpgrade: response.numberOfNightsForTierUpgrade ?? undefined,
    numberOfNightsForTierRetention: response.numberOfNightsForTierRetention ?? undefined,
    benefits: response.benefits.map((benefit): App.LuxLoyaltyBenefit => ({
      code: benefit.code,
      description: benefit.description,
      remaining: benefit.details.remaining === 'unlimited' ? undefined : benefit.details.remaining,
      limit: benefit.details.limit === 'unlimited' ? undefined : benefit.details.limit,
      unlimited: benefit.details.remaining === 'unlimited' || benefit.details.limit === 'unlimited',
      value: benefit.details.value ? {
        amount: benefit.details.value.amount,
        unit: benefit.details.value.unit,
        currency: benefit.details.value.currency,
      } : undefined,
    })),
  }
}

export function luxLoyaltyPendingPointsMapper(pendingPoints: Array<ServerLuxLoyaltyPendingPoints>): Array<App.OrderLoyaltyPendingPoints> {
  return pendingPoints.map((pendingPoint) => ({
    itemId: pendingPoint.itemId,
    points: pendingPoint.points,
    statusCredits: pendingPoint.statusCredits,
  }))
}

export function luxLoyaltyAccountActivityMapper(activities: Array<any>): Array<App.LuxLoyaltyAccountActivity> {
  return activities.map((activity) => ({
    ...activity,
  }))
}
